@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html,body{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  
}

.auto-verification-modal-container{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.671); 
}
.auto-verification-modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  z-index: 3;

}

.auto-verification-modal img{
  width: 50px;
  margin-right: 10px;
}
.auto-verification-modal p{
  font-weight: bol;
}